/* src/index.css or your custom CSS file */

body {
  background-color: #333; /* Dark background for the page */
  color: #fff;
  font-family: Arial, sans-serif;
  height: 100vh; /* Ensure the total height does not exceed the viewport */
  overflow: hidden; /* Hide any overflow */
}

@media (max-width: 768px) {
  body {
    overflow: auto;
  }
}


