.footerLegend {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 3px 0; /* Reduced padding */
  background: #333;
  box-sizing: border-box;
  z-index: 20;
  font-size: 0.9em; /* Reduce font size for smaller overall size */
}

.footerLegendItem {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px; /* Reduced margin */
  padding: 4px; /* Reduced padding */
  text-align: center;
  border-radius: 2px;
  font-weight: 600;
  font-size: 1.5em; /* Increase font size */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Text shadow */
}


.footerText {
  margin: 8px; /* Adjusted margin */
}

.colorBlock {
  display: inline-block;
  padding: 0px;
  margin: 0px;
  color: white;
  font-weight: bold;
  text-align: center;
}
