.menuItem {
  display: flex;
  background-color: white;
  color: #333;
  height: 18vh; /* Example fixed height - adjust based on actual screen and footer height */
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  overflow: hidden;
  position: relative; /* Ensure this is set to position child elements absolutely within it */
}

.styleIndicator {
  width: 20px;
  flex-shrink: 0;
}

.content {
  display: flex;
  flex-grow: 1;
}

.itemDetails {
  padding: 10px;
  flex-grow: 1;
}

.itemName {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0px;
  padding-bottom: 5px;
}

.itemDescription,
.itemAbv {
  margin: 5px 0;
}

.containerList {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #fff;
  color: #fff;
  min-width: 30%;
  font-weight: 600;
  height: 100%;
}

.container {
  display: flex;
  justify-content: space-between; /* This will separate the size and price */
  align-items: center;
  margin-bottom: 5px;
  padding: 5px;
  background-color: #333;
  border-radius: 2px;
  min-height: 15%;
}

.containerSize,
.containerPrice {
  flex-basis: 50%; /* Assign equal width to both elements */
}

.containerPrice {
  text-align: right;
}

.itemAbv {
  font-weight: bold;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  margin: 0;
  z-index: 10;
  border-top-right-radius: 4px; /* Rounded corner for the top-left of the ABV tag */
}

/* Existing styles... */

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .menuItem {
    /* Adjust the height to auto so it expands as needed */
    height: auto;
    min-height: 200px; /* Set a minimum height, adjust as needed */
  }


}

/* Continue with other styles as needed... */
