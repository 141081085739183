/* src/components/MenuGrid.module.css */
.menuGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 8px;
    box-sizing: border-box;
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    .menuGrid {
      grid-template-columns: 1fr; /* Single column on mobile */
    }
  }

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff; /* Example background color, adjust as needed */
  height: 18vh; /* Match the height of your menu items */
  border-radius: 4px;
  overflow: hidden; /* Hide anything that goes outside the border-radius */
}

.imageWrapper img {
  width: 100%; /* Ensure image covers the full width */
  height: 100%; /* Ensure image covers the full height *//* This makes the image cover the available space without stretching */
  border-radius: inherit;
}

.apiImage {
  object-fit: cover; 
}

.placeholderImage {
  object-fit: none;/* Specific styles for placeholder images */
}